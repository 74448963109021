console.log('It\'s working!')

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

// components can be called from the imported UIkit reference
// UIkit.notification('Hello world.');

// youtube embed
var youtube = document.querySelectorAll( ".youtube" );

for (var i = 0; i < youtube.length; i++) {

    if(youtube[i].dataset.thumb == '1') {

        // thumbnail image source.
        var source = "https://img.youtube.com/vi/"+ youtube[i].dataset.embed +"/maxresdefault.jpg";

        // Load the image asynchronously
        var image = new Image();
        image.src = source;
        image.addEventListener( "load", function() {
            youtube[ i ].appendChild( image );
        }( i ) );
    }


    // Create iframe on click
    youtube[i].addEventListener( "click", function() {
        this.style.height = this.clientHeight + 'px';
        this.style.width = this.clientWidth + 'px';

        var iframe = document.createElement( "iframe" );

            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "uk-responsive", "" );
            iframe.setAttribute( "src", "https://www.youtube-nocookie.com/embed/"+ this.dataset.embed +"?rel=0&showinfo=0&autoplay=1" );
            iframe.setAttribute( "allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture");

            this.innerHTML = "";
            this.appendChild( iframe );
    } );
}

// load more on scroll
var element= document.getElementById("work-elements");

if(element) {
    var limit = parseInt(element.dataset.limit);
    var category = element.dataset.category;
    var offset = limit;
    var url=element.dataset.page+".json";

    var button = document.getElementById("load-more");

    if(button) {
        button.addEventListener("click", function(event){
            event.preventDefault()

            var loader = document.getElementById("loader");

            button.style.display = "none";
            loader.style.display = "block";

            const data = {
                limit: limit,
                offset: offset,
                cat: category
            };

            var jsonRequest = new XMLHttpRequest();
            jsonRequest.open("POST", url, true);
            jsonRequest.setRequestHeader("content-type", "application/json; charset=UTF-8");
            jsonRequest.onreadystatechange = function() {
                if (jsonRequest.readyState === 4) {
                    if (jsonRequest.status === 200) {
                        var jsoncontent = JSON.parse(jsonRequest.responseText);
                        element.innerHTML += jsoncontent.html;
                        loader.style.display = "none";

                        if(jsoncontent.more !== false) {
                            button.style.display = "inline-block";
                        }

                    } else {
                        console.log('There was a problem with the request.');
                    }
                }
            }
            jsonRequest.send(JSON.stringify(data));

            offset += limit;

        });
    }
}


// function element_in_scroll(elem)
// {
//     var docViewTop = $(window).scrollTop();
//     var docViewBottom = docViewTop + $(window).height();
//
//     var elemTop = $(elem).offset().top;
//     var elemBottom = elemTop + $(elem).height();
//
//     return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
// }
//
// $(function(){
//
//   var element = $('.work');
//   var url     = element.data('page') + '.json';
//   var limit   = parseInt(element.data('limit'));
//   var offset  = limit;
//   var category = element.data('category');
//
//   $('.load-more').on('click', function(e) {
//     e.preventDefault();
//
//     $.get(url, {limit: limit, offset: offset, cat: category}, function(data) {
//
//       if(data.more === false) {
//         $('.load-more').hide();
//       }
//       element.children().last().after(data.html);
//       //$('.work-item').hide().fadeIn('slow');
//
//       offset += limit;
//
//     });
//
//   });
//   function onScroll() {
//   	if ($(".load-more").length && element_in_scroll(".load-more")) {
//   			$(document).unbind('scroll', onScroll);
//         // console.log('Yes');
//         $.get(url, {limit: limit, offset: offset, cat: category}, function(data) {
//
//           if(data.more === false) {
//             $('.load-more').hide();
//           }
//           element.children().last().after(data.html);
//           //$('.work-item').hide().fadeIn('slow');
//
//           offset += limit;
//
//           // console.log(data.more);
//           if(data.more != false) {
//             $(document).bind('scroll', onScroll);
//           }
//         });
//       };
//   };
//   $(document).scroll(onScroll);
// });
